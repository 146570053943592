












import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TargetEpisodeList extends Vue {
  @Prop() episode!: any

  mounted() {}

  publishedDate(date: string) {
    return moment(date).format('MMM DD, YYYY')
  }
}
