var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex justify-between w-100 pv1 bg-adori-light-gray ph4 br2 b--adori-gray",
    },
    [
      _c("div", { staticClass: "flex w-100 justify-between items-center" }, [
        _c("div", { staticClass: "flex items-center" }, [
          _c("img", {
            attrs: {
              src: _vm.episode.imageUrl,
              alt: "ep_img",
              width: "40",
              height: "40",
            },
          }),
          _c("p", { staticClass: "ml5 f5" }, [
            _vm._v(_vm._s(_vm.episode.name)),
          ]),
        ]),
        _c("div", [_vm._v(_vm._s(_vm.publishedDate(_vm.episode.publishedOn)))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }