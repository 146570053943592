var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: true },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("no")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          {
            staticClass: "w-100 flex justify-center f5 pv4 white",
            staticStyle: { width: "350px" },
          },
          [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]
        ),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex justify-end items-center w-100 white" },
          [
            _c(
              "div",
              { staticClass: "mb2 flex justify-between" },
              [
                _c("BaseButtonBorder", {
                  staticClass: "bw0 bg-adori-light-gray fr",
                  attrs: {
                    text: "NO",
                    onClick: function () {
                      _vm.$emit("no")
                    },
                  },
                }),
                _c("BaseButtonRed", {
                  staticClass: "fl ml2",
                  attrs: {
                    text: "YES",
                    onClick: function () {
                      _vm.$emit("yes")
                    },
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }