const campaignKind: { [key: string]: string } = {
  PAID_PROMO: 'Paid Promo',
  UNPAID_PROMO: 'Unpaid Promo',
  PROGRAMMATIC: 'Programmatic',
}

export const getCampaignKind = (kind: string) => {
  return campaignKind[kind]
}

export const options = {
  text: {
    color: '#FFFFFF',
    shadowEnable: true,
    shadowColor: '#000000',
    fontSize: 14,
    fontFamily: 'Helvetica',
    dynamicPosition: true,
    hideText: true,
  },
  progress: {
    color: '#de1a23',
    backgroundColor: 'grey',
    inverted: false,
  },
  layout: {
    height: 10,
    verticalTextAlign: 61,
    horizontalTextAlign: 43,
    zeroOffset: 0,
    strokeWidth: 30,
    progressPadding: 0,
    type: 'line',
  },
}
