var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex justify-between w-100 pv2 bb-l b--adori-gray" },
    [
      _c("div", { staticClass: "flex items-center" }, [
        !_vm.view
          ? _c(
              "i",
              {
                staticClass:
                  "material-icons f3 white link pointer red adori-red pointer mr3",
                on: { click: _vm.unselect },
              },
              [_vm._v("check_circle")]
            )
          : _vm._e(),
        _c("img", {
          attrs: {
            src: _vm.podcast.imageUrl,
            alt: "show_img",
            width: "40",
            height: "40",
          },
        }),
        _c("p", { staticClass: "ml5 f5" }, [_vm._v(_vm._s(_vm.podcast.name))]),
      ]),
      _c("div", { staticClass: "flex justify-between items-center" }, [
        _c("p", { staticClass: "f6 mr3" }, [
          _vm._v(_vm._s(_vm.target.count) + " Episodes"),
        ]),
        _c(
          "i",
          {
            staticClass: "material-icons f3 white link pointer pointer",
            on: { click: _vm.changeSection },
          },
          [_vm._v("arrow_forward_ios")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }