
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class TargetList extends Vue {
  @Prop(Boolean) view!: boolean
  @Prop() target!: any
  @Getter rssFeedsByUid!: any
  @Getter targetEpisodeList!: any

  get podcast() {
    return this.rssFeedsByUid[this.target.feedUid] || {}
  }

  get episode() {
    return this.targetEpisodeList[this.target.feedUid] || {}
  }

  unselect() {
    this.$store.commit('unselectEpisodes', this.target.feedUid)
  }

  changeSection() {
    this.$emit('section', this.target.feedUid)
  }
}
